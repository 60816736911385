<template>
  <div class="sampledetail">
    <div class="title">基础信息</div>
    <van-cell-group class="mt10">
      <van-cell title="检测状态" v-show="dataList.check_status == 0" value="待检测" />
      <van-cell title="检测状态" v-show="dataList.check_status == 1" value="检测中" />
      <van-cell title="检测状态" v-show="dataList.check_status == 2" value="初检完成" />
      <van-cell title="检测状态" v-show="dataList.check_status == 3" value="检测完成" />
      <van-cell title="检测状态" v-show="dataList.check_status == 4" value="让步审批中" />
      <van-cell title="检测状态" v-show="dataList.check_status == 5" value="审批完成" />                 
      <van-cell title="报检类型" :value="dataList.inspect.type.type_name" />
      <van-cell title="报检编号" :value="dataList.inspect.inspect_code" />
      <van-cell title="报检时间" :value="dataList.inspect.inspect_time" />
    </van-cell-group>
    <div class="title">报检员信息</div>
    <van-cell-group class="mt10">
      <van-cell title="报检员ID" :value="dataList.inspect.inspect_emp_id" />
      <van-cell title="报检员姓名" :value="dataList.inspect.inspect_emp_name" />
      <van-cell title="报检员部门" :value="dataList.inspect.inspect_dep_name" />
      <van-cell title="是否申请复检" v-show="dataList.is_inspect_recheck == 0" value="否" />
      <van-cell title="是否申请复检" v-show="dataList.is_inspect_recheck == 1" value="是" /> 
      <van-cell title="申请复检时间" v-show="dataList.is_inspect_recheck > 0" :value="dataList.inspect_recheck_time" /> 
    </van-cell-group>
    <div class="title">样品信息</div>
    <van-cell-group class="mt10">
      <van-cell title="产品编号" :value="dataList.inspect.sample_code" />
      <van-cell title="产品名称" :value="dataList.inspect.sample_name" />
      <van-cell title="产品规格型号" :value="dataList.inspect.sample_specs" />
      <van-cell title="报检批次" :value="dataList.inspect.inspect_batch" />
      <van-cell title="产品供应商" :value="dataList.inspect.supplier_name" />
      <van-cell title="合同编号" :value="dataList.inspect.contract_no" />
      <van-cell title="原产品" v-if="dataList.inspect.original_product" :value="dataList.inspect.original_product" />
      <van-cell title="生产线" v-if="dataList.inspect.production_line"  :value="dataList.inspect.production_line" />
      <van-cell title="数量"   v-if="dataList.inspect.inspect_num" :value="dataList.inspect.inspect_num" />
      <van-cell title="ERP到货单号"  v-if="dataList.inspect.erp_purchase_arrival_no" :value="dataList.inspect.erp_purchase_arrival_no" />
      <van-cell title="车牌号" v-if="dataList.inspect.car_number"  :value="dataList.inspect.car_number" />
      <van-cell title="报检重要说明" :value="dataList.inspect.inspect_remark" />
      <van-cell title="其他说明" :value="dataList.inspect.other_remark" />
      <van-cell title="样品附件信息" /> 
      <van-cell-group class="mt10">
        <span style="text-align: center; width: 33.33%;display: inline-block;" v-for="item in dataList.inspect.attach_id" :key="item">
          <van-image width="100" height="100" :src="item" @click="bigImg()" />
        </span>              
      </van-cell-group>               
    </van-cell-group>
    <div v-show="dataList.check_status > 1">
      <div class="title">检测情况</div>
        <van-cell-group class="mt10">                              
          <van-cell title="检样员" :value="dataList.check_emp_name" />
          <van-cell title="初检说明" :value="dataList.check_remark" />
          <van-cell title="初检时间" v-show="dataList.check_status > 1" :value="dataList.check_time" />                         
          <van-cell title="复检员" :value="dataList.recheck_emp_name" />
          <van-cell title="复检说明" v-show="dataList.check_status > 1" :value="dataList.recheck_remark" />
          <van-cell title="复检时间" v-show="dataList.check_status > 2" :value="dataList.recheck_time" />                   
          <van-cell title="检测设备" :value="equipmentName" />
          <van-cell title="检测结果" v-show="dataList.conclusion == 1" value="合格" />
          <van-cell title="检测结果" v-show="dataList.conclusion == 0" value="不合格" />
        </van-cell-group>
    </div>
    <div v-show="dataList.check_status > 1">
      <div class="title">检测项目</div>
      <div style='position:relative;'>      
        <van-icon class="status" v-if="dataList.conclusion == 1" class-prefix="icon-font" name="hege" color="#07c160" size="42"/>
        <van-icon class="status" v-if="dataList.conclusion == 0" class-prefix="icon-font" name="buhege" color="#ee0a24" size="42"/> 
        <ve-table :columns="columns" :table-data="dataList.check_items" :border-x="true" :border-y="true" /> 
      </div>
    </div>
    <div v-if="+dataList.check_status > 3 ">
      <div class="title">申请情况</div>
      <van-cell title="申请人"  input-align="right" readonly :value="dataList.inspect.inspect_emp_name" />
      <van-cell title="申请原因"  input-align="right" readonly :value="dataList.concession_acceptance_reason" /> 
      <van-cell title="申请时间"  input-align="right" readonly :value="dataList.concession_acceptance_time" />
    </div>
    <div v-if="+dataList.check_status === 5">
        <div class="title">审批情况</div>
        <van-cell title="审批人"  input-align="right" readonly :value="dataList.approval_emp_name" />
        <van-cell title="备注"  input-align="right" readonly v-model="approvalRemark" />
        <van-cell title="审批附件" />
        <van-cell v-if="dataList.attach_id.length > 0">
          <span style="text-align: center; width: 33.33%; display: inline-block;" v-for="item in dataList.attach_id" :key="item">
            <van-image width="100" height="100" :src="item" @click="bigImgFj()" />
          </span>
        </van-cell>
        <van-cell title="审批结果" input-align="right">
          <template>
            <van-tag v-if="+dataList.is_concession_acceptance === 0" type="danger" size="medium">不同意</van-tag>
            <van-tag v-else type="success" size="medium">同意</van-tag>
          </template>
        </van-cell>
        <van-cell title="时间"  input-align="right" readonly v-model="dataList.approval_time" />
    </div>
    <div class="mt10 btn-r">
      <van-button class="ml5" round plain size="small" v-if="toFlag" type="default" @click="toCheck">返回列表</van-button>
      <van-button class="ml5" round plain size="small" v-else type="default" @click="toList">返回列表</van-button>
      <van-button class="ml5" round plain size="small" type="default"  v-if="isPhone == false"  v-print="print" v-show="dataList.check_status == 5 || dataList.check_status == 3">打印报告</van-button>
    </div>
    <div id="printMe">
      <h3 class="tac">{{dataList.inspect.type.type_name}}化验单</h3>
      <table class="table-cle" border="1px; solid #000;" width="100%" height="50" style="background: #fafafa;">
        <tr style="border-bottom: 1px solid #000;width: 100%">
          <td style="width: 10%"><span>原料名称</span></td>
          <td colspan="2" style="width: 40%"><span>{{dataList.inspect.sample_name}}</span></td>
          <td style="width: 10%"><span>来料单位</span></td>
          <td colspan="2" style="width: 40%"><span>{{dataList.inspect.supplier_name}}</span></td>
        </tr>
        <tr>
          <td style="width: 10%"><span>批号</span></td>
          <td style="width: 30%"><span>{{dataList.inspect.inspect_batch}}</span></td>
          <td style="width: 10%"><span>采样人</span></td>
          <td style="width: 20%"><span>{{dataList.inspect.inspect_emp_name}}</span></td>
          <td style="width: 10%"><span>数量</span></td>
          <td style="width: 20%"><span>{{dataList.inspect.inspect_num}}</span></td>
        </tr>        
      </table>
      <ve-table class="item"
        :columns="proColumns"
        :table-data="printData"
        :border-around="true"
        :border-x="true"
        :border-y="true"        
        :cell-span-option="cellSpanOption"
        style="border: 1px solid #000;border-top: none;"
      />
      <table class="table-cle" border="1px" width="100%" height="25" style="margin-top: -1px;">        
        <tr style="background: #fafafa;">
          <td width="80px"><span>报检备注</span></td>          
          <td colspan="6"><span>{{dataList.inspect.inspect_remark}}</span></td>           
        </tr>    
        <tr style="background: #fafafa;">
          <td width="80px"><span>初检备注</span></td>          
          <td colspan="6"><span>{{dataList.check_remark}}</span></td>           
        </tr>       
      </table>
      <div class="mt10" style="font-size: 12px;display: flex;">
        <span style="flex: 1;text-align: center;">检验人：{{dataList.check_emp_name}}</span>
        <span style="flex: 1;">复检人：{{dataList.recheck_emp_name}}</span>
        <span style="flex: 1;">化验时间：{{dataList.recheck_time}}</span>
      </div>
      <!-- <van-row gutter="10" style="background-color: #fafafa;">        
        <van-col span="4">
          <van-cell style="background-color: #fafafa;" title="检验：" :value="dataList.check_emp_name" />
        </van-col>       
        <van-col span="4">
          <van-cell style="background-color: #fafafa;" title="复检：" :value="dataList.recheck_emp_name" />
        </van-col> 
        <van-col span='4'></van-col>
        <van-col span="12">
          <van-cell style="background-color: #fafafa;" title="化验时间：" :value="dataList.recheck_time" />
        </van-col>       
      </van-row> -->
    </div>
  </div>
</template>
<script>

import Vue from 'vue'
import { Cell, CellGroup, Step, Steps, Toast, Image as VanImage, ImagePreview, Col, Row, Field, Button } from 'vant'
import { showPrivateOss } from '@/common/fileUpload'
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Toast)
Vue.use(VanImage)
Vue.use(ImagePreview)
Vue.use(Col)
Vue.use(Row)
Vue.use(Field)
Vue.use(Button)

export default {
  data() {
    return { 
      toFlag: this.$route.query.toFlag,
      isPhone: this.$common.isMobilePhone(),
      id: this.$route.query.id || localStorage.getItem('id'),
      equipmentName: '',
      approvalRemark: '', //让步备注
      dataList:{
        check_items:[],
        inspect:{
          type: {}
        }
      },
      printData:[],
      columns: [
          { field: "check_name", key: "a", title: "检测项", align: "center" },
          { field: "check_standard", key: "b", title: "参考指标", align: "center" },
          { field: "check_value", key: "c", title: "检测值", align: "center" },
          { field: "single_conclusion", key: "d", title: "单项结论", align: "center" },
      ],
      cellSpanOption: {
          bodyCellSpan: this.bodyCellSpan,
        },
      proColumns: [
          { field: "check_name", key: "a", title: "检测项", align: "center" },
          { field: "check_standard", key: "b", title: "参考指标", align: "center" },
          { field: "check_value", key: "c", title: "检测值", align: "center" },
          { field: "single_conclusion", key: "d", title: "单项结论", align: "center" },
          { field: "conclusion", key: "e", title: "最终结论", align: "center" },
        ],
      proTableData: [],
      print: {
        id: 'printMe',
        popTitle: '配置页眉标题', // 打印配置页上方的标题
        extraHead: '', // 最上方的头部文字，附加在head标签上的额外标签，使用逗号分割
        preview: true, // 是否启动预览模式，默认是false
        previewTitle: '预览的标题', // 打印预览的标题
        previewPrintBtnLabel: '预览结束，开始打印', // 打印预览的标题下方的按钮文本，点击可进入打印
        zIndex: 20002, // 预览窗口的z-index，默认是20002，最好比默认值更高               
        standard: '',
        extarCss: '',
        previewBeforeOpenCallback () { 
          // console.log('正在加载预览窗口！')
        }, // 预览窗口打开之前的callback
        previewOpenCallback () { 
          document.querySelector('#printMe').setAttribute('style','height: 0 !important')
        }, // 预览窗口打开时的callback
        beforeOpenCallback () { 
          // console.log('开始打印之前！') 
        }, // 开始打印之前的callback
        openCallback () { 
          // console.log('执行打印了！') 
        }, // 调用打印时的callback
        closeCallback () { 
          
          
        }, // 关闭打印的callback(无法区分确认or取消)
        clickMounted () {
          document.querySelector('#printMe').setAttribute('style','height: auto !important')
        },
      }       
    }
  },
  created: function () {    
    const type = this.$route.query.type
    const id = this.$route.query.id
    const role = this.$store.state.router.user.role
    if (type === '1' && role.indexOf('CheckRole') !== -1) {
      this.$router.push({path: '/AnalysisTb', query: { id: id} })
    }
    this.getDetail()
  },
  methods: {
    getDetail() {
      var that = this
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      this.$post('check/get-check-sample-info', 'api', { id: this.id, is_detail: 1}).then(res => { 
        Toast.clear()
        const data = res.data 
        data.check_items.forEach(ele => {
          if(data.is_concession_acceptance == '1'){
            if(data.conclusion === '1') {
              ele.conclusion = '合格[让步]'
            } else {
              ele.conclusion = '不合格[让步]'
            }
          } else {
            if(data.conclusion === '1') {
              ele.conclusion = '合格'
            } else {
              ele.conclusion = '不合格'
            }
          }
          if(ele.single_conclusion === '1') {
            ele.single_conclusion = '合格'
          } else {
            ele.single_conclusion = '不合格'
          }
        })
        data.inspect.inspect_time = that.$common.initDate(data.inspect.inspect_time)
        data.check_time = that.$common.initDate(data.check_time)
        data.concession_acceptance_time = that.$common.initDate(data.concession_acceptance_time)
        data.inspect_recheck_time = that.$common.initDate(data.inspect_recheck_time)
        data.recheck_time = that.$common.initDate(data.recheck_time)
        // data.inspectPic = data.inspect.attach_id
        this.dataList = data
        this.approvalRemark = data.approval_remark
        this.printData = data.check_items
        let equipmentName = []
        if(data.equipment_items) {
          data.equipment_items.forEach(ele => {
            equipmentName.push(ele.equipment_name)
          })
          this.equipmentName = equipmentName.join(',')
        }
        that.$common.scrollToTop()
      }, err => {
        Toast.clear()
      })
    },
    // 查看大图
    async bigImg () {
      const pics = this.dataList.inspect.attach_id
      let picsShow = []
      for (let i = 0; i < pics.length; i++) {
        const url = await showPrivateOss(pics[i]?.attach_name, "")
        picsShow.push(url)
      }
      ImagePreview({
        images: picsShow,
        showIndex: true,
        loop: true,
        closeable: true       
      })
    },
    async bigImgFj () {
      const pics = this.dataList.attach_id
      let picsShow = []
      for (let i = 0; i < pics.length; i++) {
        const url = await showPrivateOss(pics[i]?.attach_name, "")
        picsShow.push(url)
      }
      ImagePreview({
        images: picsShow,
        showIndex: true,
        loop: true,
        closeable: true       
      })
    },
    bodyCellSpan({ row, column, rowIndex }) {         
      if (column.field === "conclusion") {
        if (rowIndex === 0) {
          return {
            rowspan: this.dataList.check_items.length,
            colspan: 1,
          }
        }else if (rowIndex >= 1) {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }           
      } 
  },
  // 返回列表
  toList() {
    // window.history.back(-1)
    this.$router.push({
      path: '/analysislist'
    })
  },
  toCheck() {
    this.$router.push({
      path: '/CheckList'          
    })
  }
}
}
</script>

<style scoped>
.tac {
  text-align: center;
}
.status{
  position: absolute;
  top: -30px;
  right: 0px;
  width: 80px;
  height: 80px;
  z-index: 98;
 }
.sampledetail{
  padding-bottom: 60px;
  padding-top: 10px;
}
.title{
  font-size: 14px;
  color: #565656;
  margin: 10px 16px;
}
.van-step__icon h6, 
.van-step__title h6{
  margin: 0;
}
.title+p.gray{
  background-color: #fff;
}
.btn-r{
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
}

table {
  border-spacing: 0px;
  border-collapse: collapse;
  font-size: 12px;
  color: #000000d9;
}
.table-cle td {
  text-align: center;
  border-right-color: #000; 
}
#printMe {
  height: 0; 
  overflow: hidden;
  padding: 0 10px !important;
}
@media print {
  #printMe {
    height: auto;
    display: block !important;
  }
  .item{
    display: block !important;
  }
}

  @page{
    size:  auto;   
    margin: 3mm; 
  }
 
  html{
    background-color: #FFFFFF;
    margin: 0;  
  }
  #printMe >>> .ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content thead.ve-table-header tr.ve-table-header-tr th.ve-table-header-th{
    border-right-color: #000;
    border-bottom-color: #000;
  }
  #printMe >>> .ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content tbody.ve-table-body tr.ve-table-body-tr td.ve-table-body-td, #printMe >>> .ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content tbody.ve-table-body tr.ve-table-expand-tr td.ve-table-body-td {
    border-right: 1px solid #000;
    border-bottom-color: #000;
  }
  #printMe >>> .ve-table-body-tr:nth-child(2) .ve-table-body-td:nth-child(4) {
    
  }
  #printMe >>> .ve-table-body-tr:nth-child(2) .ve-table-body-td:last-child{            
    border-bottom: none;
    border-right: none !important;
  }
  #printMe >>> .ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content thead.ve-table-header tr.ve-table-header-tr, #printMe >>> .ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content tbody.ve-table-body tr.ve-table-body-tr, #printMe >>> .ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content tbody.ve-table-body tr.ve-table-expand-tr {
  height: 20px !important;
}
#printMe >>> .ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content thead.ve-table-header tr.ve-table-header-tr th.ve-table-header-th,#printMe >>> .ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content tbody.ve-table-body tr.ve-table-body-tr td.ve-table-body-td,#printMe >>> .ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content tbody.ve-table-body tr.ve-table-expand-tr td.ve-table-body-td {
  padding: 0 10px !important;
}

#printMe >>> .ve-table .ve-table-container {
  height: 100% !important;
}
</style>
